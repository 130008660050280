<template lang="pug">
span
  confirmation-dialog(
    v-model="showing"
    @okBtnClicked="remove"
    @failBtnClicked="toggle"
    title="Remove MIJN reservation"
    descr="Are you sure you want to remove this candidate from MIJN reservation?"
    okText="ok"
    failText="cancel"
  )
  v-btn(
    @click="toggle"
    outlined
    small
  ).btn-common REMOVE MIJN EXAM
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
export default {
  props: {
    ID: Number,
    isMijnReservation: Boolean
  },
  mixins: [errorsMixin],

  inject: ['svc'],

  data: () => ({
    showing: false
  }),

  methods: {
    toggle() {
      this.showing = !this.showing
    },

    async remove() {
      try {
        await this.svc().removeMijnExam(this.ID)
        this.$notify({text: 'MIJN exam removed', type: 'success'})
      } catch (error) {
        this.processError(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM
        })
      }
    }
  },

  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>
